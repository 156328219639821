<template>
  <div class="imei-history">
    <KTCodePreview v-bind:title="'Lịch sử IMEI'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col md="3">
            <b-input size="sm" placeholder="Nhập mã IMEI" v-model="searchIMEI" v-on:keyup.enter="onFilter()"></b-input>
          </b-col>
          <b-col md="3">
            <b-input size="sm" placeholder="Nhập mã, tên sản phẩm" v-model="searchProduct"
              v-on:keyup.enter="onFilter()"></b-input>
          </b-col>
          <b-col md="3">
            <div class="search-col">
              <b-input-group>
                <date-picker placeholder="Từ" class="form-control form-control-sm" :config="dpConfigs.date"
                  v-model="searchFromDay"></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker placeholder="Đến" class="form-control form-control-sm" :config="dpConfigs.date"
                  v-model="searchToDay"></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col md="3">
            <b-form-select class="select-style" size="sm" v-model="selectedAction" :options="listAction" value-field="id"
              text-field="name" v-on:change="fetchData"></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button style="fontweight: 600; width: 70px" variant="primary" size="sm" @click="onFilter">Lọc</b-button>
          </b-col>
        </b-row>
        <b-table :items="listImeis" :fields="fields" :bordered="true" :hover="true" :busy="onLoading">
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(action)="row">
            <span v-text="row.item.action"></span>
            <br />
            <span v-text="row.item.storeName" style="color: blue"></span>
          </template>
          <template v-slot:cell(productName)="row">
            <p v-if="row.item.productCode" style="font-size: 11px; margin-bottom: 0">
              {{ row.item.productCode }}
            </p>
            <span v-text="row.item.productName" style="white-space: normal"></span>
          </template>
          <template v-slot:cell(billCode)="row">
            <div class="d-flex justify-content-center billCode" @click="linkToBill(row.item)" style="cursor: pointer">
              <span v-text="row.item.billCode"></span>
            </div>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center billCode" @click="linkToImei(row.item)" style="cursor: pointer">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="row">
            <span v-text="row.item.createdBy"></span>
            <br />
            <span v-text="row.item.createdAt"></span>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <div class="d-flex justify-content-end">
            <b-col>
              <b-pagination-nav class="custom-pagination" v-if="numberOfPage >= 2" :link-gen="linkGen"
                :number-of-pages="numberOfPage" use-router @change="fetchData" align="right"
                first-class="page-item-first btn btn-icon m-1 btn-sm" prev-class="page-item-prev btn btn-icon m-1 btn-sm"
                next-class="page-item-next btn btn-icon btn-sm m-1 " last-class="page-item-last btn btn-icon btn-sm m-1"
                page-class="btn btn-icon btn-sm border-0 m-1">
                <template v-slot:first-text>
                  <span>
                    <i class="ki ki-double-arrow-back icon-xs"></i>
                  </span>
                </template>

                <template v-slot:prev-text>
                  <i class="ki ki-arrow-back icon-xs"></i>
                </template>

                <template v-slot:next-text>
                  <i class="ki ki-arrow-next icon-xs"></i>
                </template>

                <template v-slot:last-text>
                  <span class="text-info">
                    <i class="ki ki-double-arrow-next icon-xs"></i>
                  </span>
                </template>
              </b-pagination-nav>
            </b-col>
          </div>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
.imei-history {
  .labelInput {
    font-weight: 600;
  }

  .productCode:hover {
    text-decoration: underline;
  }

  .page-item.disabled {
    cursor: not-allowed;

    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }



  .icon:hover {
    background-color: #90c6fc;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .table.b-table.table-sm>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }

  .billCode:hover {
    text-decoration: underline;
  }

  .search-col {
    display: flex;
    align-items: center;
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import decounce from 'debounce';
import moment from 'moment';

export default {
  data() {
    return {
      selectedProduct: '',
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Hành động',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '7%',
          },
        },
        {
          key: 'billCode',
          label: 'Loại',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '20%',
          },
        },
        {
          key: 'providerName',
          label: 'Nhà cung cấp',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'saleName',
          label: 'Nhân viên BH',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
          tdClass: 'width: 15%',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'width: 15%',
        },
      ],
      searchIMEI: '',
      listImeis: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      selectedAction: null,
      listAction: [
        { id: null, name: 'Tất cả hành động' },
        { id: 1, name: 'Nhập kho' },
        { id: 2, name: 'Xuất kho' },
        { id: 3, name: 'Chuyển kho' },
        { id: 4, name: 'Cập nhật phiếu nhập kho' },
        { id: 5, name: 'Cập nhật phiếu xuất kho' },
        { id: 6, name: 'Xuất chuyển kho' },
        { id: 7, name: 'Nhập chuyển kho' },
        { id: 8, name: 'Xuất kho bán lẻ' },
        { id: 9, name: 'Xuất kho bán buôn' },
        { id: 10, name: 'Nhập từ phiếu trả hàng' },
        { id: 11, name: 'Nhập kho từ đơn chuyển hoàn' },
        { id: 12, name: 'Xuất kho giao hàng' },
        { id: 13, name: 'Cân kho' },
        { id: 14, name: 'Nhập kho bảo hành' },
        { id: 15, name: 'Cập nhật nhập kho bảo hành' },
        { id: 16, name: 'Xuất kho bảo hành' },
        { id: 17, name: 'Cập nhật xuất kho bảo hành' },
        { id: 18, name: 'Đổi sản phẩm' },
        { id: 19, name: 'Xóa sản phẩm trong hóa đơn' },
        { id: 20, name: 'Xóa hóa đơn' },
        { id: 21, name: 'Xóa hóa đơn trả hàng' },
        { id: 22, name: 'Cập nhật hóa đơn' },
        { id: 23, name: 'Xóa phiếu chuyển kho' },
        { id: 24, name: 'Xóa phiếu nhập' },
        { id: 25, name: 'Xóa phiếu nhập' },
        { id: 26, name: 'Nhập thu cũ' },
        { id: 27, name: 'Hủy phiếu chuyển' },
        { id: 28, name: 'Nhập giữ hàng' },
        { id: 29, name: 'Cập nhật phiếu giữ hàng' },
        { id: 30, name: 'Xóa phiếu giữ hàng' },
        { id: 31, name: 'Xuất trả giữ hàng' },
        { id: 32, name: 'Xóa phiếu trả giữ hàng' },
        { id: 33, name: 'Hủy phiếu giữ hàng' },
        { id: 34, name: 'Nhập kho từ phiếu kiểm tồn kho' },
        { id: 35, name: 'Xuất kho từ phiếu kiểm tồn kho' },
      ],
      onLoading: false,
      numberOfPage: 0,
      searchProduct: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      searchFromDay: '',
      searchToDay: '',
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'IMEI', route: '/imei-histories' },
      { title: 'Lịch sử IMEI' },
    ]);
  },
  created() {
    let imeiCode = this.$route.query.code;
    if (imeiCode !== undefined) {
      this.searchIMEI = imeiCode;
    }
    this.fetchData();
  },
  methods: {
    convertPrice: function (number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchData: function () {
      this.onLoading = true;
      this.page = this.$route.query.page ? this.$route.query.page : 1;

      const param = {
        page: this.page,
        limit: 10,
        action: this.selectedAction,
        searchIMEI: this.searchIMEI,
        searchProduct: this.searchProduct,
        fromDate: this.searchFromDay
          ? moment(this.searchFromDay, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 00:00:00'
          )
          : null,
        toDate: this.searchToDay
          ? moment(this.searchToDay, 'DD/MM/YYYY').format('yyyy-MM-DD 23:59:59')
          : null,
      };

      let paramQuery = {
        params: param,
      };

      ApiService.query('imeiHistories/v2', paramQuery)
        .then(({ data }) => {
          this.listImeis = [];
          this.numberOfPage = data.data.totalPage;
          this.totalItem = data.data.totalRow;
          data.data.data.forEach((element, index) => {
            let billCode = element.code;
            if (
              element.stockSlipType === 6 ||
              element.stockSlipType === 7 ||
              element.stockSlipType === 8 ||
              element.stockSlipType === 10
            ) {
              billCode = element.billNumber;
            }
            let count = 0;
            if (this.page === 1) {
              count = index + 1;
            } else {
              count = index + 1 + (this.page - 1) * 10;
            }
            let item = {
              count: count,
              imeiId: element.id,
              imeiCode: element.imeiCode,
              productName: element.productName,
              productCode: element.productCode,
              providerName: element.providerName,
              saleName: element.saleName,
              action: element.actionName,
              code: element.code,
              createdBy: element.createdBy,
              stockSlipId: element.stockSlipId,
              type: element.stockSlipType,
              createdAt: element.createdAt,
              billCode: billCode,
              storeName: element.storeName,
            };
            this.listImeis.push(item);
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    editItem: function (item) {
      this.$router.push({
        path: 'imeis/update-imei',
        query: { id: item.id },
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    linkToBill: function (item) {
      if (item.type === 8) {
        const { href } = this.$router.resolve({
          name: 'list-return-bill',
          query: { id: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 1) {
        const { href } = this.$router.resolve({
          name: 'update-stock',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 2) {
        const { href } = this.$router.resolve({
          name: 'update-export-stock',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 3) {
        const { href } = this.$router.resolve({
          name: 'update-transfer-stock',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 5) {
        const { href } = this.$router.resolve({
          name: 'list-require-stock',
          query: { code: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 6) {
        const { href } = this.$router.resolve({
          name: 'update-bill',
          query: { id: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 7) {
        const { href } = this.$router.resolve({
          name: 'update-wholesale-invoice',
          query: { id: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 11) {
        const { href } = this.$router.resolve({
          name: 'update-import-trade-in',
          query: { id: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 12) {
        const { href } = this.$router.resolve({
          name: 'update-transfer-stock-to-nhanh',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 13) {
        const { href } = this.$router.resolve({
          name: 'list-require-stock-to-nhanh',
          query: { code: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 15) {
        const { href } = this.$router.resolve({
          name: 'list-import-warranty',
          query: { code: item.billCode },
        });
        window.open(href, '_blank');
      } else if (item.type === 16) {
        const { href } = this.$router.resolve({
          name: 'list-export-warranty',
          query: { code: item.billCode },
        });
        window.open(href, '_blank');
      }
    },
    linkToImei: function (item) {
      const { href } = this.$router.resolve({
        name: 'list-imeis',
        query: { code: item.imeiCode },
      });
      window.open(href, '_blank');
    },
    debounceInput: decounce(function () {
      this.fetchData();
    }, 2000),
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-imei-history',
      });
      this.fetchData();
    },
  },
};
</script>
